import { createSlice } from '@reduxjs/toolkit'
import { get, post } from './apiService'

export const getLoginDetails = async ({ userId, password }) => {
    return get({
        url: `https://gokulgr.com/tz-server/index.php/Api/runQuery?query=select * from cc_sales_agent where login='${userId}' and password='${password}'`
    })
}

const initialState = {
    isLoggedIn: false,
}

export const loginSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setAuthState: (state, action) => {
            state.isLoggedIn = action.payload
        }

    },
})

// Action creators are generated for each case reducer function
export const { setAuthState } = loginSlice.actions

export default loginSlice.reducer