import { createSlice } from '@reduxjs/toolkit'
import { get, post } from './apiService'


export const getAgentList = async () => {
    return get({
        url: `https://gokulgr.com/tz-server/index.php/Api/runQuery?query=select id,code,name,role,name as 'label' from cc_sales_agent`
    })
}

export const assignLeadsToAgents = async (values) => {
    let query = `UPDATE cc_lead 
SET assignedTo_profileId = '${values?.assignToProfile?.code}' , expectedClose='${values?.expectedCloseDate}' 
WHERE assignedTo_profileId IS NULL 
LIMIT ${values.leadCount}`
    console.log(values, "values")
    console.log(query, "query")
    return get({
        url: `https://gokulgr.com/tz-server/index.php/Api/runUpdateDeleteQuery?query=${query}`
    })
}

export const updateLeadDetail = async ({ payload, id }) => {
    return post({ url: `https://gokulgr.com/tz-server/index.php/Api/postUpdate/cc_lead/${id}`, payload: JSON.stringify(payload) })
}

export const createLeadNotes = async ({ payload }) => {
    return post({ url: `https://gokulgr.com/tz-server/index.php/Api/postInsert/cc_lead_notes/`, payload: JSON.stringify(payload) })
}

export const createLeadActivity = async ({ payload }) => {
    return post({ url: `https://gokulgr.com/tz-server/index.php/Api/postInsert/cc_activity/`, payload: JSON.stringify(payload) })
}

export const getLeadsForAgent = () => {
    let agentCode = JSON.parse(localStorage.getItem('userInfo')).code

    let query = `select * from cc_lead where assignedTo_profileId = '${agentCode}' order by id asc limit 500`
    return post({ url: 'https://gokulgr.com/tz-server/index.php/Api/runPostQuery', payload: JSON.stringify({ query: query }) })
}


export const getLeadList = async (limit = 50, offset = 0, searchHint = null, assignedTo = 'unassigned', leadId = null) => {

    let assignedCondition = ''

    switch (assignedTo) {
        case 'unassigned': {
            assignedCondition = 'assignedTo_profileId is null'
            break
        }
        case 'assigned': {
            assignedCondition = 'assignedTo_profileId is not null'
            break
        }
        case 'closed': {
            assignedCondition = 'isClosed is not null'
            break
        }
        case 'rejected': {
            assignedCondition = 'isRejected is not null'
            break
        }
        default: {
            assignedCondition = 'assignedTo_profileId is null'
        }
    }

    let searchCondition = `${searchHint ? `and sa.name like "%${searchHint}%" or l.name like "%${searchHint}%" or l.mobile like "%${searchHint}%"` : ``}`

    let id_condition = `${leadId ? `and l.id = ${leadId}` : ``}`

    let query = `select sa.name as 'owner',l.* FROM cc_lead l left JOIN cc_sales_agent sa ON l.assignedTo_profileId = sa.code where ${assignedCondition} ${searchCondition} ${id_condition} order by id asc limit ${limit} offset ${offset}`
    console.log("query", query)

    return post({ url: 'https://gokulgr.com/tz-server/index.php/Api/runPostQuery', payload: JSON.stringify({ query: query }) })
}

export const getLeadNotes = (leadId) => {
    let query = `select n.*,sa.name from cc_lead_notes n join cc_sales_agent sa on sa.code = n.createdBy where lead_id ='${leadId}' order by n.id desc`
    return post({ url: 'https://gokulgr.com/tz-server/index.php/Api/runPostQuery', payload: JSON.stringify({ query: query }) })
}

export const getLeadActivity = (leadId) => {
    let query = `select a.*,sa.name from cc_activity a join cc_sales_agent sa on sa.code = a.createdBy  where lead_id ='${leadId}' order by a.scheduleDate asc`
    return post({ url: 'https://gokulgr.com/tz-server/index.php/Api/runPostQuery', payload: JSON.stringify({ query: query }) })
}

export const twilioOutgoing = () => {
    return get({ url: `https://gokulgr.com/tz-server/index.php/Api/twilioOutgoing` })
}

export const getTwilioAccessToken = () => {
    // return get({ url: `https://token-service-7872-dev.twil.io/token` })// twilio cloud function - vinit acc
    return get({ url: `https://gokulgr.com/tz-server/index.php/Api/getTwilioAccessToken` })
}

export const twilioConsoleFunction = () => {
    return post({ url: `https://tokenservice-1841.twil.io/getaccesstoken`, payload: { clientName: "test client" } })
}

export const leadSlice = createSlice({
    name: 'product',
    initialState: {
        list: [],
        count: 0,
        columns: [
            { id: 'name', lookupName: 'Name', align: 'left' },
            { id: 'mobile', lookupName: 'Mobile', align: 'left' },
            { id: 'city', lookupName: 'City', align: 'left' },
            { id: 'owner', lookupName: 'Owner', align: 'right' },
            { id: 'button', lookupName: 'Action', align: 'right' },
        ],
        rows: [],
        // Lead Detail Page State
        limit: 10,
        offset: 0,
        searchHint: null,
        leadSummary: {},
        audit: [],
        activity: [],
        quote: [],
        attachment: [],
        contact: [],
        notes: [],
        transactions: [],
        selectedLead: {}
    },
    reducers: {
        setSelectedLead: (state, action) => {
            state.selectedLead = action.payload
        },
        setNotes: (state, action) => {
            state.notes = action?.payload
        },
        setActivity: (state, action) => {
            state.activity = action?.payload
        },
        setList: (state, action) => {
            state.rows = action?.payload
        },
        setCount: (state, action) => {
            state.count = action.payload
        },
        setLimit: (state, action) => {
            state.limit = action.payload
        },
        setOffset: (state, action) => {
            state.offset = action.payload
        },
        setSearchHint: (state, action) => {
            state.searchHint = action.payload
        }
    },
})

export const {
    setList,
    setCount,
    setLimit,
    setOffset,
    setSearchHint,
    setSelectedLead,
    setNotes,
    setActivity
} = leadSlice.actions

export default leadSlice.reducer