import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Stack from '@mui/material/Stack';

export default function BreadCrumb({ page, entity, nobackButton = false }) {
    return (
        <div role="presentation" >
            <Stack direction="row" alignItems="center" spacing={2}>
                <IconButton color="primary" aria-label="upload picture" component="label" onClick={() => { window.history.go(-1); return false; }}>
                    <ArrowBackIosNewIcon />
                </IconButton>

                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        {page}
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        href="/material-ui/getting-started/installation/"
                    >
                        {entity}
                    </Link>
                </Breadcrumbs>
            </Stack>
        </div>
    );
}