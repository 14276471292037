import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

// Redux
import { store } from './redux/_store'
import { Provider } from 'react-redux'

// Router
import { BrowserRouter, Route, Routes } from "react-router-dom"

// Pages
import Login from './pages/login/login'
import Layout from './layout/layout'

// Style imports
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'animate.css';
import { ThemeProvider, createTheme, PaletteOptions } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />)

export default function App() {

  console.log(window.matchMedia('(prefers-color-scheme:dark)'))
  const [isDarkTheme, setIsDarkTheme] = React.useState(window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false);
  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addListener((e) => e.matches ? setIsDarkTheme(true) : setIsDarkTheme(false));
  })
  const [loader, showLoader] = React.useState(false)

  return (
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider theme={isDarkTheme ? createTheme({ palette: { mode: "dark" } }) : createTheme({ palette: { mode: "light" } })}>
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
            onClick={() => { showLoader(false) }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Layout />} />
              </Routes>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  );
}



