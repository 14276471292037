import * as React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    DataGridPro,
    GridColumns,
    GridRowsProp,
    GridActionsCellItem,
} from '@mui/x-data-grid-pro';
import {
    randomCreatedDate,
    randomTraderName,
    randomEmail,
    randomUpdatedDate,
} from '@mui/x-data-grid-generator';

export default function DataTableColumnFixed() {
    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGridPro
                rows={rows}
                columns={columns}
                initialState={{ pinnedColumns: { left: ['name'], right: ['actions'] } }}
            />
        </div>
    );
}

const columns = [
    { field: 'name', headerName: 'Name', width: 160, editable: true },
    { field: 'email', headerName: 'Email', width: 200, editable: true },
    { field: 'age', headerName: 'Age', type: 'number', editable: true },
    {
        field: 'dateCreated',
        headerName: 'Date Created',
        type: 'date',
        width: 180,
        editable: true,
    },
    {
        field: 'lastLogin',
        headerName: 'Last Login',
        type: 'dateTime',
        width: 220,
        editable: true,
    },
    {
        field: 'actions',
        type: 'actions',
        width: 100,
        getActions: () => [
            <GridActionsCellItem icon={<EditIcon />} label="Edit" />,
            <GridActionsCellItem icon={<DeleteIcon />} label="Delete" />,
        ],
    },
];

const rows = [
    {
        id: 1,
        name: randomTraderName(),
        email: randomEmail(),
        age: 25,
        dateCreated: randomCreatedDate(),
        lastLogin: randomUpdatedDate(),
    },
    {
        id: 2,
        name: randomTraderName(),
        email: randomEmail(),
        age: 36,
        dateCreated: randomCreatedDate(),
        lastLogin: randomUpdatedDate(),
    },
    {
        id: 3,
        name: randomTraderName(),
        email: randomEmail(),
        age: 19,
        dateCreated: randomCreatedDate(),
        lastLogin: randomUpdatedDate(),
    },
    {
        id: 4,
        name: randomTraderName(),
        email: randomEmail(),
        age: 28,
        dateCreated: randomCreatedDate(),
        lastLogin: randomUpdatedDate(),
    },
    {
        id: 5,
        name: randomTraderName(),
        email: randomEmail(),
        age: 23,
        dateCreated: randomCreatedDate(),
        lastLogin: randomUpdatedDate(),
    },
    {
        id: 6,
        name: randomTraderName(),
        email: randomEmail(),
        age: 27,
        dateCreated: randomCreatedDate(),
        lastLogin: randomUpdatedDate(),
    },
    {
        id: 7,
        name: randomTraderName(),
        email: randomEmail(),
        age: 18,
        dateCreated: randomCreatedDate(),
        lastLogin: randomUpdatedDate(),
    },
    {
        id: 8,
        name: randomTraderName(),
        email: randomEmail(),
        age: 31,
        dateCreated: randomCreatedDate(),
        lastLogin: randomUpdatedDate(),
    },
    {
        id: 9,
        name: randomTraderName(),
        email: randomEmail(),
        age: 24,
        dateCreated: randomCreatedDate(),
        lastLogin: randomUpdatedDate(),
    },
    {
        id: 10,
        name: randomTraderName(),
        email: randomEmail(),
        age: 35,
        dateCreated: randomCreatedDate(),
        lastLogin: randomUpdatedDate(),
    },
];