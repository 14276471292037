import * as React from 'react';
// import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';

import { Card } from 'antd';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function getAvatarText(fullName) {
    const nameArray = fullName.split(" ");
    const firstName = nameArray[0];
    const lastName = nameArray[1];
    return `${firstName?.charAt(0)?.toUpperCase() || ''}${lastName?.charAt(0)?.toUpperCase() || ''}`;
}

export default function ContactCard({ checked, name, mobile, onCheck, stage, onClick, owner }) {

    return (
        <>
            <Card style={{ padding: 0, cursor: 'pointer' }} title={name} bordered={true} onClick={() => { onClick() }}>
                <p>Mobile : +91 {mobile}</p>
                <p>Owner  : {owner}</p>
                <p>Stage  : {stage}</p>
            </Card>
        </>
    );
}