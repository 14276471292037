import * as React from 'react';
import { Divider, List, Typography, Input, Empty, Button, DatePicker } from 'antd';
import { Grid } from '@mui/material';
import * as dayjs from 'dayjs'


const { TextArea } = Input;

export default function LeadFollowup({ activity, createActivity }) {

    const [newNote, setNewNote] = React.useState('')
    const [activityDate, setActivityDate] = React.useState(null)

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={3} xl={3} lg={3}>
                    <DatePicker style={{ width: '100%' }} value={+activityDate && dayjs(+activityDate)} onChange={(date, dateString) => { setActivityDate(date) }} />
                </Grid>
                <Grid item xs={12} md={8} xl={8} lg={6}>
                    <TextArea value={newNote} onChange={(event) => { setNewNote(event?.target?.value) }} placeholder="Enter notes here" rows={1} />
                </Grid>
                <Grid item xs={12} md={3} xl={3} lg={3}>
                    <Button onClick={() => {
                        createActivity({ scheduleDate: activityDate, notes: newNote })
                        setNewNote('')
                        setActivityDate('')
                    }}>Add Activity</Button>
                </Grid>
            </Grid>

            <List style={{ width: '100%', marginTop: 20 }}>
                {
                    activity?.length > 0 ? activity?.map(item =>
                        <>
                            <List
                                bordered
                                size="small"
                            >
                                <List.Item>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <b>{dayjs(+item?.scheduleDate).format('DD/MM/YYYY')} {formatDate(+item?.scheduleDate)}</b> -  {item?.notes}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <span style={{ fontSize: '0.7rem' }}>{item?.name} on  {dayjs(+item?.created).format('DD/MM/YYYY')}</span>
                                        </Grid>
                                    </Grid>
                                </List.Item>
                            </List>
                        </>)
                        :
                        <Empty style={{ marginTop: 100 }}></Empty>
                }
            </List>
        </>
    );
}

function formatDate(timestamp) {
    const date = new Date(timestamp);
    const now = new Date();

    // If the date is today
    if (date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear()) {
        return "Today";
    }

    // If the date is tomorrow
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    if (date.getDate() === tomorrow.getDate() && date.getMonth() === tomorrow.getMonth() && date.getFullYear() === tomorrow.getFullYear()) {
        return "Tomorrow";
    }

    // // If the date is this week
    // const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
    // const endOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay() + 6);
    // if (date >= tomorrow && date >= startOfWeek && date <= endOfWeek) {
    //     return "this week";
    // }

    // // If the date is this month
    // const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    // const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    // if (date >= tomorrow && date >= startOfMonth && date <= endOfMonth) {
    //     return "this month";
    // }

    // Otherwise, return the formatted date
    return '';
}