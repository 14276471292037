import { configureStore } from '@reduxjs/toolkit'
import LoginSlice from './loginSlice'
import productSlice from './productSlice'
import leadSlice from './leadSlice'

export const store = configureStore({
    reducer: {
        LoginSlice,
        productSlice,
        leadSlice
    },
})