import * as React from 'react';
// import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { deepOrange, deepPurple } from '@mui/material/colors';

import { Card } from 'antd';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function getAvatarText(fullName) {
    const nameArray = fullName.split(" ");
    const firstName = nameArray[0];
    const lastName = nameArray[1];
    return `${firstName?.charAt(0)?.toUpperCase() || ''}${lastName?.charAt(0)?.toUpperCase() || ''}`;
}

export default function ContactCard({ checked, name, mobile, onCheck, stage, onClick, owner, selectedLead }) {

    // React.useEffect(() => { console.log(props, "Props contact") }, props)

    return (
        <>
            <Card className={`leadListView ${selectedLead?.name == name && selectedLead?.mobile == mobile ? 'activeLead' : ''}`} title={name} bordered={true} onClick={() => { onClick() }}>
                {/* <p>Mobile : +91 {mobile}</p> */}
                {/* <p>Owner  : {owner}</p> */}
                {/* <p>Stage  : {stage}</p> */}
            </Card>
        </>
        // <Card elevation={5} style={{ margin: 2, maxHeight: 100, backgroundImage: 'linear-gradient(to right, #dccfe6 , #f8f1f1)' }}>
        //     <CardActionArea>
        //         {/* <CardActions style={{ padding: 10 }}>
        //         </CardActions> */}
        //         <CardContent style={{ padding: 10 }}>

        //             <Grid container spacing={2}>
        //                 <Grid item xs={2} md={2}>
        //                     <Avatar sx={{ bgcolor: deepPurple[500] }}>
        //                         {getAvatarText(name)}</Avatar>
        //                 </Grid>
        //                 <Grid item xs={8} md={8} onClick={() => { onClick() }} style={{ wrap: 'no-wrap' }}>
        //                     <Typography gutterBottom variant="subtitle1" component="div" >
        //                         {name?.substring(0, 20)}{name?.length > 20 ? '...' : ''}
        //                     </Typography>
        //                     <Typography variant="paragraph" color="text.secondary" style={{ marginTop: 5 }}>
        //                         <span> +91 {mobile}</span>
        //                     </Typography><br></br>

        //                     {
        //                         owner &&
        //                         <Typography gutterBottom variant="subtitle1" color="text.secondary" style={{ marginTop: 5 }}>
        //                             <span><i>{owner}</i></span>
        //                         </Typography>
        //                     }

        //                 </Grid>
        //             </Grid>


        //         </CardContent>
        //     </CardActionArea>
        //     {/* <CardActions>
        //         <Button size="small" color="primary">
        //             + Notes
        //         </Button>
        //         <Button size="small" color="secondary">
        //             + Todo
        //         </Button>
        //     </CardActions> */}
        // </Card>
    );
}