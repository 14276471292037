import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.primary,
        // color: theme.palette.common.white,
        fontSize: 12,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function DataTable(props) {
    const [rows, setRows] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    React.useEffect(() => {
        setRows(props?.rows || [])
        setColumns(props?.columns || [])
        setCount(props?.count || 0)
        setRowsPerPage(props?.limit || 0)
    }, [props])



    const handleChangePage = (
        event,
        newPage
    ) => {
        setPage(newPage);
        props.onPagination(rowsPerPage, newPage * rowsPerPage)
    };

    const handleChangeRowsPerPage = (
        event
    ) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
        props.onPagination(event.target.value, 0)
    };

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>

                <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                    <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 700 }}>
                        <TableHead >
                            <TableRow>
                                {
                                    columns.map((columnItem) => <StyledTableCell align={columnItem.align} key={columnItem.id}>{columnItem.lookupName}</StyledTableCell>)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody className='ion-content-scroll-host'>

                            {rows?.length > 0 ? rows.map((row) => (
                                <StyledTableRow key={row.name}>
                                    {
                                        columns.map((columnItem) => (<>
                                            <StyledTableCell key={row[columnItem.lookupName]} align={columnItem.align}>
                                                {
                                                    row[columnItem.id] == 'edit_button' ? <IconButton size="small" onClick={() => { props.onActionButtonClick(row) }}><CreateIcon></CreateIcon></IconButton>
                                                        : row[columnItem.id] == 'add_button' ? <IconButton size="small" onClick={() => { props.onActionButtonClick(row) }}><AddIcon></AddIcon></IconButton>
                                                            : row[columnItem.id] == 'view_button' ? <IconButton size="small" onClick={() => { props.onActionButtonClick(row) }}><VisibilityIcon></VisibilityIcon></IconButton>
                                                                : row[columnItem.id] == 'delete_button' ? <IconButton size="small" onClick={() => { props.onActionButtonClick(row) }}><DeleteOutlineIcon></DeleteOutlineIcon></IconButton>
                                                                    : row[columnItem.id] == 'menu_button' ? <IconButton size="small" onClick={() => { props.onActionButtonClick(row) }}><MoreVertIcon></MoreVertIcon></IconButton>
                                                                        : row[columnItem.id]
                                                }
                                            </StyledTableCell></>))
                                    }
                                </StyledTableRow>
                            )) : <>
                                Loading
                            </>}
                        </TableBody>
                    </Table>

                </TableContainer>
                <TablePagination
                    component="div"
                    count={count}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[5, 10, 50, 100]}
                />
            </Paper>
        </>
    );
}
