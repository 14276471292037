import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Grid } from '@mui/material';

import { Input, Select, DatePicker, RadioChangeEvent, Radio, Divider, List, Typography, Button } from 'antd';
import Stack from '@mui/material/Stack';
import * as dayjs from 'dayjs'


export default function InsetList({ onSave, setIsFormEdited }) {

    const { selectedLead } = useSelector((state) => state.leadSlice);

    const [summaryFormState, setSummaryFormState] = React.useState({})
    const [formEdited, setFormEdited] = React.useState(false)
    React.useEffect(() => {
        setSummaryFormState(selectedLead)
    }, [selectedLead])

    React.useMemo(() => {
        if (!Object.values(summaryFormState).every((val, index) => val === Object.values(selectedLead)[index])) {
            setIsFormEdited(true)
            setFormEdited(true)
        }
        else {
            setIsFormEdited(false)
            setFormEdited(false)
        }

    }, [summaryFormState])

    return (
        <>
            <Grid container spacing={2}>

                <Grid item xs={12} md={12}>
                    <List
                        bordered
                        size="small"
                    >
                        <List.Item>
                            <Button disabled={!formEdited} type="primary" onClick={() => { onSave(summaryFormState) }}>Save Changes</Button>
                        </List.Item>

                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Name :</span><Input onChange={(event) => { setSummaryFormState({ ...summaryFormState, name: event.target.value }) }} placeholder="Enter Name" value={summaryFormState?.name} defaultValue={summaryFormState?.name} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Stage :</span> <Select
                                    defaultValue={summaryFormState?.stage}
                                    style={{ width: '100%' }}
                                    value={summaryFormState?.stage}
                                    onChange={(event, value) => { setSummaryFormState({ ...summaryFormState, stage: value.value }) }}
                                    options={[
                                        {
                                            value: 'Prospecting',
                                            label: 'Prospecting',
                                        },
                                        {
                                            value: 'Qualifying',
                                            label: 'Qualifying',
                                        },
                                        {
                                            value: 'E-KYC',
                                            label: 'E-KYC',
                                        },
                                        {
                                            value: 'V-KYC',
                                            label: 'V-KYC',
                                        },
                                    ]}
                                />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Mobile :</span><Input placeholder="Mobile" value={summaryFormState?.mobile} defaultValue={summaryFormState?.mobile} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Email :</span><Input onChange={(event) => { setSummaryFormState({ ...summaryFormState, email: event.target.value }) }} placeholder="Enter Email" value={summaryFormState?.email} defaultValue={summaryFormState?.email} />
                            </Stack>
                        </List.Item>

                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  City :</span><Input onChange={(event) => { setSummaryFormState({ ...summaryFormState, city: event.target.value }) }} placeholder="Enter City" value={summaryFormState?.city} defaultValue={summaryFormState?.city} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Address :</span><Input onChange={(event) => { setSummaryFormState({ ...summaryFormState, address: event.target.value }) }} placeholder="Enter Address" value={summaryFormState?.address} defaultValue={summaryFormState?.address} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Pincode :</span><Input onChange={(event) => { setSummaryFormState({ ...summaryFormState, pincode: event.target.value }) }} placeholder="Enter Pincode" value={summaryFormState?.pincode} defaultValue={summaryFormState?.pincode} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  DOB :</span> <DatePicker style={{ width: '100%' }} value={+summaryFormState?.dob && dayjs(+summaryFormState?.dob)} onChange={(date, dateString) => { setSummaryFormState({ ...summaryFormState, dob: date }) }} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Father Name :</span><Input onChange={(event) => { setSummaryFormState({ ...summaryFormState, fatherName: event.target.value }) }} placeholder="Enter Father Name" value={summaryFormState?.fatherName} defaultValue={summaryFormState?.fatherName} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Maiden Name :</span><Input onChange={(event) => { setSummaryFormState({ ...summaryFormState, maidenName: event.target.value }) }} placeholder="Enter Maiden Name" value={summaryFormState?.maidenName} defaultValue={summaryFormState?.maidenName} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  PAN :</span><Input onChange={(event) => { setSummaryFormState({ ...summaryFormState, pan: event.target.value }) }} placeholder="Enter PAN" value={summaryFormState?.pan} defaultValue={summaryFormState?.pan} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Employment :</span><Radio.Group style={{ width: '100%' }} options={['Self Employed', 'Salaried']} onChange={(event, value) => { setSummaryFormState({ ...summaryFormState, employmentType: event.target.value }) }} value={summaryFormState.employmentType} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Company Name :</span><Input onChange={(event) => { setSummaryFormState({ ...summaryFormState, companyName: event.target.value }) }} placeholder="Enter Company Name" value={summaryFormState?.companyName} defaultValue={summaryFormState?.companyName} />
                            </Stack>
                        </List.Item>
                        <List.Item>
                            <Stack direction="row" style={{ width: '100%' }}>
                                <span style={{ padding: 5, width: 150 }}>  Monthly Income :</span><Input onChange={(event) => { setSummaryFormState({ ...summaryFormState, monthlySalary: event.target.value }) }} placeholder="Enter Monthly Income" value={summaryFormState?.monthlySalary} defaultValue={summaryFormState?.monthlySalary} />
                            </Stack>
                        </List.Item>
                    </List>

                </Grid>

            </Grid>
        </>
    );
}