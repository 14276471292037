import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

import DatePicker from "react-datepicker";

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.subtitle2,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
}));

export default function LeadSummaryForm({ width = 400, title = 'Lead Info', isFormOpen, setFormState, setFormValues, formValues }) {

    const textFieldStyle = { width: '100%' }
    const errorMessageStyle = { color: 'red' }
    const stageLov = [
        { label: 'Qualifying' },
        { label: 'E KYC' },
        { label: 'V KYC' },
        { label: 'Closed' },
        { label: 'Reject' }
    ]
    const employmentTypeLov = [
        { label: 'Salaried' },
        { label: 'Self Employed' },
    ]

    return (
        <div >
            <SwipeableDrawer
                anchor={'right'}
                open={isFormOpen}
                onClose={() => setFormState(false)}
            >
                <Box
                    sx={{ width: width, maxWidth: '100%', padding: 2, height: 500 }}
                    role="presentation"
                >
                    {/* <Grid container spacing={2}>

                        <Grid item xs={12} md={6}>

                            <Div>{title}</Div>

                        </Grid>
                        <Grid item xs={12} md={6}>

                            <div style={{ float: 'right' }}>
                                <Button variant='contained' onClick={() => { }}>Save</Button>
                            </div>
                            <div style={{ float: 'right', marginRight: 10 }}>
                                <Button variant='outlined' onClick={() => { setFormState(false) }}>Cancel</Button>
                            </div>

                        </Grid>
                    </Grid> */}

                    {
                        formValues?.name &&
                        <Formik
                            initialValues={formValues}
                            validate={values => {
                                const errors = {};
                                errors.name = !values.name ? 'Required' : null;
                                errors.mobile = !values.mobile ? 'Required' : null;
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue
                                /* and other goodies */
                            }) => (
                                <>

                                    <Grid container spacing={2}>

                                        <Grid item xs={6} md={4}>

                                            <Div>{title}</Div>

                                        </Grid>
                                        <Grid item xs={6} md={8}>

                                            <div style={{ float: 'right' }}>
                                                <Button variant='contained' onClick={() => { setFormValues(values) }}>Save</Button>
                                            </div>
                                            <div style={{ float: 'right', marginRight: 10 }}>
                                                <Button variant='outlined' onClick={() => { setFormState(false) }}>Cancel</Button>
                                            </div>

                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={2} style={{ marginTop: 10 }}>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                required
                                                id="name"
                                                style={textFieldStyle}
                                                label="Name"
                                                defaultValue={values.leadName}
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}

                                            />
                                            <span style={errorMessageStyle}> {errors.name && touched.name && errors.name}</span>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DatePicker placeholderText="Date Of Birth" selected={(new Date(+values?.dob) !== "Invalid Date" && !isNaN(new Date(+values?.dob)) && new Date(+values?.dob) || new Date())} id="datepicker" className="datePickerInput" onChange={(date) => { console.log(date, "Date"); setFieldValue('dob', date) }} />
                                            <span style={errorMessageStyle}> {errors.dob && touched.dob && errors.dob}</span>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="mobile"
                                                style={textFieldStyle}
                                                label="Mobile*"
                                                defaultValue={values.mobile}
                                                value={values.mobile}
                                                name="mobile"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                            // disabled={true}
                                            />
                                            <span style={errorMessageStyle}> {errors.mobile && touched.mobile && errors.mobile} </span>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="pan"
                                                style={textFieldStyle}
                                                label="Pan*"
                                                defaultValue={values.pan}
                                                value={values.pan}
                                                name="pan"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                            // disabled={true}
                                            />
                                            <span style={errorMessageStyle}> {errors.pan && touched.pan && errors.pan} </span>

                                        </Grid>
                                        {/* <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                disablePortal
                                                id="employmentType"
                                                options={employmentTypeLov}
                                                defaultValue={values.employmentType}
                                                value={values.employmentType}
                                                name="employmentType"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                renderInput={(params) => <TextField  {...params} label="Employment Type*" />}
                                            />
                                            <span style={errorMessageStyle}> {errors.employmentType && touched.employmentType && errors.employmentType} </span>

                                        </Grid> */}
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="companyName"
                                                style={textFieldStyle}
                                                label="Company Name*"
                                                defaultValue={values.companyName}
                                                value={values.companyName}
                                                name="companyName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                            // disabled={true}
                                            />
                                            <span style={errorMessageStyle}> {errors.companyName && touched.companyName && errors.companyName} </span>

                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="email"
                                                style={textFieldStyle}
                                                label="Email (personal)"
                                                defaultValue={values.email}
                                                value={values.email}
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                            // disabled={true}
                                            />
                                            <span style={errorMessageStyle}> {errors.email && touched.email && errors.email} </span>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="maidenName"
                                                style={textFieldStyle}
                                                label="Maiden name"
                                                defaultValue={values.maidenName}
                                                value={values.maidenName}
                                                name="maidenName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                            // disabled={true}
                                            />
                                            <span style={errorMessageStyle}> {errors.maidenName && touched.maidenName && errors.maidenName} </span>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="fatherName"
                                                style={textFieldStyle}
                                                label="Father Name"
                                                defaultValue={values.fatherName}
                                                value={values.fatherName}
                                                name="fatherName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                            // disabled={true}
                                            />
                                            <span style={errorMessageStyle}> {errors.fatherName && touched.fatherName && errors.fatherName} </span>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="netMonthlyIncome"
                                                style={textFieldStyle}
                                                label="Monthly Income*"
                                                defaultValue={values.monthlySalary}
                                                value={values.monthlySalary}
                                                name="monthlySalary"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                            // disabled={true}
                                            />
                                            <span style={errorMessageStyle}> {errors.monthlySalary && touched.monthlySalary && errors.monthlySalary} </span>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="pincode"
                                                style={textFieldStyle}
                                                label="Residential Pincode*"
                                                defaultValue={values.pincode}
                                                value={values.pincode}
                                                name="pincode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                            // disabled={true}
                                            />
                                            <span style={errorMessageStyle}> {errors.pincode && touched.pincode && errors.pincode} </span>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                id="address"
                                                style={textFieldStyle}
                                                label="Residential/Permanent Address*"
                                                defaultValue={values.address}
                                                value={values.address}
                                                name="address"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                type="text"
                                            // disabled={true}
                                            />
                                            <span style={errorMessageStyle}> {errors.address && touched.address && errors.address} </span>

                                        </Grid>

                                        {/* <Grid item xs={12} md={6}>
                                            <Autocomplete
                                                disablePortal
                                                id="stage"
                                                options={stageLov}
                                                defaultValue={values.stage}
                                                value={values.stage}
                                                name="stage"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                renderInput={(params) => <TextField  {...params} label="Stage*" />}
                                            />
                                            <span style={errorMessageStyle}> {errors.stage && touched.stage && errors.stage} </span>

                                        </Grid> */}



                                    </Grid>
                                </>
                            )}
                        </Formik>
                    }


                </Box>

            </SwipeableDrawer>
        </div>
    );
}

