import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

// Redux
import {
    setSelectedLead, setList, setCount, createLeadActivity, getLeadActivity,
    setLimit, setOffset, setSearchHint, updateLeadDetail, createLeadNotes,
    getLeadList, getAgentList, getLeadNotes, setNotes, getLeadsForAgent, setActivity,
    getTwilioAccessToken, twilioOutgoing, twilioConsoleFunction
} from '../../redux/leadSlice';

// Components
import SearchBar from '../../components/searchBar';
import CreateLead from '../../components/createLead';
import AssignLeads from '../report/assignLead'
import ContactCard from '../../components/contact'

// Custom UI components
import ButtonMenu from '../../components/buttonMenu'
import TablePaginationDemo from '../../components/pagination'
import DataTable from '../../components/dataTable';
import DataTableColumnFixed from '../../components/dataTableColumnFixed';
import DataTableV1 from '../../components/dataTable_v1';
import BreadCrumb from '../../components/breadCrumb';
import ElevatedHeading from '../../components/elevatedHeading';
import AntTabs from '../../components/ant/tab'
import AntList from '../../components/ant/list'
import LeadSummary from './leadSummary';
import LeadSummaryForm from './leadSummaryForm'
import Notes from '../../components/notes';
import ActivityCard from '../../components/activityCard';

import FloatingActionButton from '../../components/fab'
import LeadDetail from './leadSplitDetail'
import LeadFollowup from './leadFollowup'

// MUI
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import { AppBar } from '@mui/material';
import { Button, Space, Empty } from 'antd';

// Twilio
import { Device } from 'twilio-client';
// import { Device } from '@twilio/voice-sdk';


function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function Lead() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()



    const { rows, selectedLead, notes, activity } = useSelector((state) => state.leadSlice);
    const [activeDetailTab, setActiveDetailTab] = React.useState(0);
    const [leadFormOpen, setLeadFormOpen] = React.useState(false);
    const [isFormEdited, setIsFormEdited] = React.useState(false)
    const [leadListOnView, setLeadListOnView] = React.useState([]);

    useEffect(() => {
        getLeads()
    }, [])

    const makeCall = () => {

        twilioConsoleFunction().then((response) => {

            const device = new Device(response.data.token, { debug: true });

            // device.setup(response.data.token)

            device.connect({
                params: {
                    To: '+917010127181'
                }
            })
        })

    }

    const getLeads = () => {
        getLeadsForAgent().then((result) => {
            dispatch(setList(result.data))
            setLeadListOnView(result.data)
        })
    }

    const getSelectedLeadNotes = (leadId) => {
        getLeadNotes(leadId).then((result) => {
            dispatch(setNotes(result.data))
        })
    }

    const getSelectedLeadActivity = (leadId) => {
        getLeadActivity(leadId).then((result) => {
            dispatch(setActivity(result.data))
        })
    }

    const handleLeadSelect = (item) => {
        dispatch(setSelectedLead({ ...selectedLead, ...item }))
    }

    const saveLeadDetail = (values) => {

        let newLeadList = [...rows]
        let modifiedLeadIndex = newLeadList.findIndex(indexItem => indexItem.id == selectedLead.id)
        newLeadList[modifiedLeadIndex] = { ...selectedLead, ...values }
        dispatch(setList(newLeadList))
        setLeadListOnView(newLeadList)

        setLeadFormOpen(false)
        dispatch(setSelectedLead({ ...selectedLead, ...values }))

        let updateObject = {
            stage: values?.stage || null,
            name: values?.name || null,
            email: values?.email || null,
            city: values?.city || null,
            address: values?.address || null,
            pincode: values?.pincode || null,
            dob: `${new Date(values?.dob).getTime()}` || null,
            fatherName: values?.fatherName || null,
            maidenName: values?.maidenName || null,
            pan: values?.pan || null,
            employmentType: values?.employmentType || null,
            companyName: values?.companyName || null,
            monthlySalary: values?.monthlySalary || null,
        }
        console.log(values, "updateObject values")
        console.log(updateObject, "updateObject")
        updateLeadDetail({ payload: updateObject, id: selectedLead.id }).then((res) => { }).catch((error) => {

        })
    }

    const createNotes = (newNote) => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let payload = {
            lead_id: selectedLead.id,
            title: newNote?.title,
            notes: newNote?.notes,
            createdBy: userInfo.code || '',
            createdOn: `${new Date().getTime()}`,
        }
        createLeadNotes({ payload }).then((result) => {
            dispatch(setNotes([{ ...payload, name: userInfo?.name || '' }, ...notes]))
        }).catch((error) => { })
    }

    const createActivity = (newActivityData) => {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let payload = {
            lead_id: selectedLead.id,
            activityType: '1', // 1 is followup
            scheduleDate: `${new Date(newActivityData?.scheduleDate).getTime()}` || null,
            notes: newActivityData?.notes,
            createdBy: userInfo.code || '',
            created: `${new Date().getTime()}`,
        }
        dispatch(setActivity([{ ...payload, name: userInfo?.name || '' }, ...activity]))
        createLeadActivity({ payload }).then((result) => {
        }).catch((error) => { })
    }

    return (
        <div className="App">
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} xl={4} lg={4}>
                    <Stack direction="row" alignItems="right" spacing={2}>
                        <SearchBar
                            onSearch={(event) => {

                                let filteredList = event && rows?.filter((filterItem) => filterItem.name.toLowerCase().includes(event.toLowerCase()))
                                console.log(filteredList, event)
                                // leadListOnView
                                setLeadListOnView(filteredList)
                            }} onClear={() => {
                                setLeadListOnView(rows)
                            }} onCreate={() => {
                                makeCall()
                            }} placeholder="Search Lead"></SearchBar>
                    </Stack>
                    <div style={{ height: 'calc(100vh - 120px)', overflow: 'scroll', padding: 5, marginTop: 10 }} className={`scrollbar-hidden listContainer`}>

                        {
                            leadListOnView?.map((item, index) =>
                                <Grid item>
                                    <ContactCard
                                        onClick={() => {
                                            if (!isFormEdited) {
                                                handleLeadSelect(item)
                                                getSelectedLeadNotes(item.id)
                                                getSelectedLeadActivity(item.id)
                                            }
                                            else {
                                                alert('Save changes')
                                            }
                                        }}
                                        checked={item.checked}
                                        name={item.name}
                                        mobile={item.mobile}
                                        city={item.city}
                                        owner={item.owner || null}
                                        stage={item.stage}
                                        selectedLead={selectedLead}

                                    ></ContactCard>
                                </Grid>
                            )
                        }
                    </div>

                </Grid>


                <Grid item xs={12} md={8}>
                    {
                        selectedLead?.name ? <>
                            <Stack direction="row" alignItems="right" spacing={2}>
                                <AntTabs onChange={(index) => { setActiveDetailTab(index) }} tabItems={['Summary', 'Notes', 'Followup']}></AntTabs>

                            </Stack>



                            <div style={{ height: 'calc(100vh - 120px)', overflow: 'scroll', padding: 5 }} className={`scrollbar-hidden listContainer`}>
                                {
                                    activeDetailTab == 0 ? <>
                                        <LeadSummary onSave={(newLeadDetail) => {
                                            saveLeadDetail(newLeadDetail)
                                        }}
                                            setIsFormEdited={(value) => { setIsFormEdited(value) }}
                                        ></LeadSummary></> : <></>
                                }
                                {
                                    activeDetailTab == 1 && <Notes notes={notes} createNote={(newNote) => { createNotes(newNote) }}></Notes>
                                }
                                {
                                    activeDetailTab == 2 && <LeadFollowup activity={activity} createActivity={(newActivity) => { createActivity(newActivity) }}></LeadFollowup>
                                }

                            </div>
                        </>
                            :
                            <div class="center">
                                <p>
                                    <Empty
                                        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                        imageStyle={{
                                            height: 100,
                                        }}
                                        style={{ width: '100%' }}
                                        description={
                                            <span>
                                                Select any lead to view details.
                                            </span>
                                        }
                                    >
                                    </Empty>
                                </p>
                            </div>
                    }
                </Grid>


            </Grid>


        </div>
    );
}

{/* <FloatingActionButton clicked={() => { setLeadForm({ ...leadForm, formStatus: true }) }}></FloatingActionButton> */ }
{/* <div style={{ float: 'right', marginRight: 10 }}>
                        <ButtonMenu
                            onClick={() => { alert(1); setAssignLeadForm({ ...assignLeadForm, formStatus: value }) }}
                        ></ButtonMenu>
                    </div> */}
