import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { setList, setCount, setLimit, setOffset, setSearchHint, getLeadList, updateLeadDetail } from '../../redux/leadSlice';

// Components
import LeadSummary from './leadSummary';
import LeadSummaryForm from './leadSummaryForm'
import Notes from '../../components/notes';

import ContactCard from '../../components/contact';
import CreateLead from '../../components/createLead';
import FormDrawer from '../../layout/formDraw';
import BreadCrumb from '../../components/breadCrumb';
import DataTable from '../../components/dataTable';
import DataTableColumnFixed from '../../components/dataTableColumnFixed';
import DataTableV1 from '../../components/dataTable_v1';

// Material
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StageStepper from './stageStepper';
import TimeLine from './TimeLine'
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { AppBar } from '@mui/material';

export default function LeadDetail() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const [value, setValue] = React.useState(0);
    const [leadForm, setLeadForm] = React.useState({ formStatus: false });

    const { selectedLead } = useSelector((state) => state.leadSlice);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        setLeadForm({ ...leadForm, ...selectedLead })
    }, [selectedLead])

    const saveLeadDetail = (values) => {
        setLeadForm({ ...leadForm, ...values })

        let updateObject = {
            stage: values?.stage || null,
            name: values?.name || null,
            email: values?.email || null,
            city: values?.city || null,
            address: values?.address || null,
            pincode: values?.pincode || null,
            dob: `${new Date(values?.dob).getTime()}` || null,
            fatherName: values?.fatherName || null,
            maidenName: values?.maidenName || null,
            pan: values?.pan || null,
            employmentType: values?.employmentType || null,
            companyName: values?.companyName || null,
            monthlySalary: values?.monthlySalary || null,
        }
        console.log(values, "updateObject values")
        console.log(updateObject, "updateObject")
        updateLeadDetail({ payload: updateObject, id: selectedLead.id }).then((res) => { }).catch((error) => {

        })


    }

    return (
        <div className="App">
            <Stack direction="row" alignItems="right" spacing={2}>
                <BreadCrumb page="Leads" entity={`${selectedLead.name}`} onClick={() => { setLeadForm(null) }}></BreadCrumb>
                <Button onClick={() => { setLeadForm({ ...leadForm, formStatus: true }) }}>Edit</Button>
                <Button onClick={() => { setLeadForm({ ...leadForm, formStatus: true }) }}>Quality</Button>

            </Stack>
            <LeadSummaryForm title="Lead Info"
                setFormValues={(event) => { saveLeadDetail(event) }}
                width={800}
                formState={leadForm.formStatus}
                formValues={leadForm}
                setFormState={(value) => { setLeadForm({ ...leadForm, formStatus: value }) }}
            ></LeadSummaryForm>
            <Box sx={{ width: '100%' }}>

                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs style={{ padding: 0 }} value={value} onChange={handleChange} scrollButtons="auto">

                        <Tab label="Summary" {...a11yProps(0)} />
                        <Tab label="Notes" {...a11yProps(1)} />
                        <Tab label="Activities" {...a11yProps(2)} />

                    </Tabs>
                </Box>
                <TabPanel value={value} index={0} style={{ padding: 0 }}>
                    {/* <StageStepper activeStep={1}></StageStepper> */}

                    <LeadSummary formValues={leadForm} setFormValues={(event) => { setLeadForm(event) }} setFormState={(value) => { setLeadForm({ ...leadForm, formStatus: value }) }}></LeadSummary>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Notes></Notes>
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <Notes></Notes>
                </TabPanel>
            </Box>
        </div>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

