import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.subtitle2,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
}));

export default function CreateLead({ width = 400, title = 'Create Lead', formState, setFormState }) {

    const textFieldStyle = { width: '100%' }
    const errorMessageStyle = { color: 'red' }
    const confidenceLevelLov = [
        { label: 'No Confidence' },
        { label: '20%' },
        { label: '50%' },
        { label: '80%' },
        { label: '100%' }
    ]
    const leadPotentialLov = [
        { label: 'Very Low' },
        { label: 'Low' },
        { label: 'Medium' },
        { label: 'High' },
        { label: 'Very High' }
    ]

    return (
        <div >
            <SwipeableDrawer
                anchor={'right'}
                open={formState}
                onClose={() => setFormState(false)}
            >
                <Box
                    sx={{ width: width, maxWidth: '100%', padding: 2, height: 500 }}
                    role="presentation"
                >
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6}>

                            <Div>{title}</Div>

                        </Grid>
                        <Grid item xs={12} md={6}>

                            <div style={{ float: 'right' }}>
                                <Button variant='contained' onClick={() => { }}>Save</Button>
                            </div>
                            <div style={{ float: 'right', marginRight: 10 }}>
                                <Button variant='outlined' onClick={() => { setFormState(false) }}>Cancel</Button>
                            </div>

                        </Grid>
                    </Grid>

                    <Formik
                        initialValues={{ name: '', mobile: '' }}
                        validate={values => {
                            const errors = {};
                            errors.name = !values.name ? 'Required' : null;
                            errors.mobile = !values.mobile ? 'Required' : null;
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            alert('1')
                            setTimeout(() => {
                                alert(JSON.stringify(values, null, 2));
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            /* and other goodies */
                        }) => (
                            <>
                                <Grid container spacing={2} style={{ marginTop: 10 }}>

                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            id="name"
                                            style={textFieldStyle}
                                            label="Name"
                                            defaultValue={values.leadName}
                                            name="name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}

                                        />
                                        <span style={errorMessageStyle}> {errors.name && touched.name && errors.name}</span>
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="mobile"
                                            style={textFieldStyle}
                                            label="Mobile*"
                                            defaultValue={values.mobile}
                                            name="mobile"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.mobile}
                                            type="tel"

                                        />
                                        <span style={errorMessageStyle}> {errors.mobile && touched.mobile && errors.mobile} </span>
                                    </Grid>
                                    {/* <Grid item xs={12} md={12}>
                                        <Autocomplete
                                            disablePortal
                                            id="confidenceLevel"
                                            options={confidenceLevelLov}
                                            renderInput={(params) => <TextField  {...params} label="Confidence Level" />}
                                            name="confidenceLevel"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.confidenceLevel}
                                        />
                                    </Grid> */}
                                    {/* <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            disablePortal
                                            id="leadPotential"
                                            options={leadPotentialLov}
                                            renderInput={(params) => <TextField  {...params} label="Lead Potential" />}
                                            name="leadPotential"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.leadPotential}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            type="number"
                                            id="expectedCloseAmount"
                                            style={textFieldStyle}
                                            label="Expected Amount"
                                            defaultValue={values.expectedCloseAmount}
                                            name="expectedCloseAmount"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.expectedCloseAmount}

                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <MobileDateTimePicker
                                            label="Expected Close Date"
                                            inputFormat="DD/MM/YYYY"
                                            value={values.expectedCloseDate}
                                            name="expectedCloseDate"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            renderInput={(params) => <TextField disabled {...params} style={{ width: '100%' }} />}
                                            onAccept={(e) => { alert(e) }}
                                            hideTabs={true}
                                        />
                                    </Grid> */}

                                </Grid>
                            </>
                        )}
                    </Formik>

                </Box>

            </SwipeableDrawer>
        </div>
    );
}

