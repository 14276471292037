import * as React from 'react';
import { Divider, List, Typography, Input, Empty, Button } from 'antd';
import { Grid } from '@mui/material';
import * as dayjs from 'dayjs'


const { TextArea } = Input;

export default function Notes({ notes, createNote }) {

    const [newNoteTitle, setNewNoteTitle] = React.useState('')
    const [newNote, setNewNote] = React.useState('')

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} xl={4} lg={3}><Input value={newNoteTitle} placeholder='Note Title' onChange={(event) => { setNewNoteTitle(event?.target?.value) }}></Input></Grid>
                <Grid item xs={12} md={8} xl={8} lg={6}><TextArea value={newNote} onChange={(event) => { setNewNote(event?.target?.value) }} placeholder="Enter notes here" rows={1} /></Grid>
                <Grid item xs={12} md={4} xl={4} lg={3}><Button onClick={() => {
                    createNote({ title: newNoteTitle, notes: newNote })
                    setNewNoteTitle('')
                    setNewNote('')
                }}>Add Note</Button></Grid>
            </Grid>

            <List style={{ width: '100%', marginTop: 20 }}>
                {
                    notes?.length > 0 ? notes?.map(note =>
                        <>
                            <List
                                bordered
                                size="small"
                            >
                                <List.Item>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={12}>
                                            <b>{note?.title}</b> -  {note?.notes}
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <span style={{ fontSize: '0.7rem' }}>{note?.name} on  {dayjs(+note?.createdOn).format('DD/MM/YYYY')}</span>
                                        </Grid>
                                    </Grid>
                                </List.Item>
                            </List>
                        </>)
                        :
                        <Empty style={{ marginTop: 100 }}></Empty>
                }
            </List>
        </>
    );
}