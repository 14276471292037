import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, Link } from 'react-router-dom'

// Redux
import { setSelectedLead, setList, setCount, setLimit, setOffset, setSearchHint, getLeadList, getAgentList } from '../../redux/leadSlice';

// Components
import AssignLeads from './assignLead'
import SearchBar from '../../components/searchBar';
import CreateLead from '../../components/createLead';
import ContactCard from '../../components/contact'

// Custom UI components
import VerticalTabs from '../../components/verticalTabs'
import ButtonMenu from '../../components/buttonMenu'
import TablePaginationDemo from '../../components/pagination'
import DataTable from '../../components/dataTable';
import DataTableColumnFixed from '../../components/dataTableColumnFixed';
import DataTableV1 from '../../components/dataTable_v1';
import FloatingActionButton from '../../components/fab'
import AntTabs from '../../components/ant/tab'
import AntList from '../../components/ant/list'
import Layout from '../../components/ant/layout'

// MUI
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';


function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
}

export default function Report() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const { limit, offset, searchHint, list, count, rows, columns } = useSelector((state) => state.leadSlice);

    const [leadForm, setLeadForm] = React.useState({ formStatus: false });
    const [assignLeadForm, setAssignLeadForm] = React.useState({ formStatus: false });
    const [selectedLeads, setSelectedLeads] = React.useState([]);
    const leadStageLov = [
        {
            label: 'Active', id: 1
        },
        {
            label: 'Rejected', id: 2
        },
        {
            label: 'Closed', id: 3
        },
    ]
    const [listClassName, setListClassName] = React.useState();
    const [agentList, setAgentList] = React.useState([]);

    useEffect(() => {
        console.log(selectedLeads, "selectedLeads")
    }, [selectedLeads])

    useEffect(() => {
        getLeads()
        getAgents()
    }, [])

    const handlePagination = (limit, offset) => {
        dispatch(setLimit(limit))
        dispatch(setOffset(offset))
        getLeads(limit, offset)
    }

    const getAgents = () => {
        getAgentList().then((result) => {
            setAgentList(result.data)
        })
    }

    const getLeads = (limit, offset, searchHint = null, assignedTo = null) => {
        getLeadList(limit, offset, searchHint, assignedTo).then((result) => {
            // if (!listClassName.includes('animate__fadeIn'))
            // setListClassName('animate__animated animate__fadeIn')
            dispatch(setList(result.data))
            dispatch(setCount(result.data.length))
        })
    }

    // Tabs control
    const [value, setValue] = React.useState(0);

    const handleChange = (newValue) => {
        // setListClassName('animate__animated animate__fadeOut')
        setValue(newValue);

        switch (newValue) {
            case 0: {
                getLeads(undefined, undefined, undefined, 'unassigned')
                break
            }
            case 1: {
                getLeads(undefined, undefined, undefined, 'assigned')
                break
            }
            case 2: {
                getLeads(undefined, undefined, undefined, 'closed')
                break
            }
            case 3: {
                getLeads(undefined, undefined, undefined, 'rejected')
                break
            }
        }
    };

    return (
        <div className="App">
            {/* <Layout></Layout> */}
            <AssignLeads agentList={agentList} title="Assign Leads" width={400} formState={assignLeadForm.formStatus} setFormState={(value) => { setAssignLeadForm({ ...assignLeadForm, formStatus: value }) }} ></AssignLeads>
            <CreateLead title="Create Lead Form" width={400} formState={leadForm.formStatus} setFormState={(value) => { setLeadForm({ ...leadForm, formStatus: value }) }} ></CreateLead>
            <div
            // className="fixed-content"
            >
                <Stack direction="row" alignItems="center" spacing={2} >
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={8}>
                            <AntTabs onChange={(index) => { handleChange(index) }} tabItems={['Assigned', 'Unassigned', 'Closed', 'Rejected']}></AntTabs>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ paddingTop: 0 }}>
                            <SearchBar from="Report" onSearch={(event) => getLeads(100, 0, event)} onClear={() => { getLeads(100, 0, null) }} onCreate={() => { setAssignLeadForm({ ...assignLeadForm, formStatus: true }) }} placeholder="Search Lead"></SearchBar>
                        </Grid>
                        {/* <Grid item xs={12} md={4}>
                            <div style={{ float: 'right' }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => { setLeadForm({ ...leadForm, formStatus: true }) }}
                                >+ Create Lead</Button>
                            </div>
                            <div style={{ float: 'right', marginRight: 10 }}>
                                <Button
                                    variant="outlined"
                                    onClick={() => { setAssignLeadForm({ ...assignLeadForm, formStatus: true }) }}
                                >Assign Leads</Button>
                            </div>
                        </Grid> */}
                    </Grid>


                    {/* <Box sx={{ width: '100%' }}>
                        <SearchBar onSearch={(event) => getLeads(100, 0, event)} onClear={() => { getLeads(100, 0, null) }} placeholder="Search Lead"></SearchBar>
                    </Box>


                    <Box sx={{ width: '100%' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="nav tabs example">
                            <LinkTab label="Unassigned" href="/drafts" />
                            <LinkTab label="Assigned" href="/trash" />
                            <LinkTab label="Closed" href="/spam" />
                            <LinkTab label="Rejected" href="/spam" />
                        </Tabs>
                    </Box>

                    <Box sx={{ width: '100%' }}>
                        <div style={{ float: 'right' }}>
                            <Button
                                variant="outlined"
                                onClick={() => { setLeadForm({ ...leadForm, formStatus: true }) }}
                            >+ Create Lead</Button>
                        </div>
                        <div style={{ float: 'right', marginRight: 10 }}>
                            <Button
                                variant="outlined"
                                onClick={() => { setAssignLeadForm({ ...assignLeadForm, formStatus: true }) }}
                            >Assign Leads</Button>
                        </div>
                    </Box> */}
                </Stack>
            </div>

            {/* <div style={{
                marginTop: 10
            }}>
                <DataTable
                    onActionButtonClick={(item) => {
                        navigate(`/lead/${item["id"]}`)
                    }}
                    onPagination={() => { }}
                    count={count}
                    rows={rows}
                    columns={columns}
                    dataList={list}
                    limit={limit}>
                </DataTable>
            </div> */}
            {/* <AntList rows={rows} onClick={(event) => { console.log(event) }}></AntList> */}

            <div style={{ marginTop: 10, height: 'calc(100vh - 130px)', overflow: 'scroll', padding: 5 }} className={`scrollbar-hidden listContainer ${listClassName}`}>
                <Grid container spacing={1}>
                    {
                        rows?.map((item, index) =>
                            <Grid item xs={12} md={6} xl={3} lg={3} onClick={() => { }}>
                                <ContactCard onCheck={(event) => {
                                    if (event) {
                                        setSelectedLeads([...selectedLeads, item])
                                    }
                                    else {
                                        setSelectedLeads(selectedLeads.filter((filterItem) => filterItem.id != item.id))
                                    }
                                }}
                                    onClick={() => {
                                        dispatch(setSelectedLead(item))
                                        navigate(`/lead/${item.id}`)
                                    }}
                                    checked={item.checked}
                                    name={item.name}
                                    mobile={item.mobile}
                                    city={item.city}
                                    owner={item.owner || null}
                                ></ContactCard>
                            </Grid>
                        )
                    }
                </Grid>
            </div>


        </div>
    );
}

{/* <FloatingActionButton clicked={() => { setLeadForm({ ...leadForm, formStatus: true }) }}></FloatingActionButton> */ }
{/* <div style={{ float: 'right', marginRight: 10 }}>
                        <ButtonMenu
                            onClick={() => { alert(1); setAssignLeadForm({ ...assignLeadForm, formStatus: value }) }}
                        ></ButtonMenu>
                    </div> */}
