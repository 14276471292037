import { Tabs } from 'antd';
// const onChange = (key) => {
//     console.log(key);
// };
const App = ({ tabItems, onChange }) => (
    <Tabs
        onChange={(index) => { onChange(index) }}
        type="card"
        items={tabItems.map((item, i) => {
            return {
                label: item,
                key: i,
                children: ``,
            };
        })}
    />
);
export default App;