import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { MobileDateTimePicker } from '@mui/x-date-pickers';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { assignLeadsToAgents } from '../../redux/leadSlice';


const Div = styled('div')(({ theme }) => ({
    ...theme.typography.subtitle2,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
}));

export default function AssignLeads({ width = 400, title = 'Create Lead', formState, setFormState, agentList }) {

    const textFieldStyle = { width: '100%' }
    const errorMessageStyle = { color: 'red' }

    const assignLead = (values) => {

        assignLeadsToAgents(values).then((result) => {
            setFormState(false)
        })

    }


    return (
        <div >
            <SwipeableDrawer
                anchor={'right'}
                open={formState}
                onClose={() => setFormState(false)}
            >
                <Box
                    sx={{ width: width, maxWidth: '100%', padding: 2, height: 500 }}
                    role="presentation"
                >


                    <Formik
                        initialValues={{
                            leadCount: 100,
                            assignToProfile: null,
                            expectedCloseDate: new Date()
                        }}
                        validate={values => {
                            const errors = {};
                            errors.leadCount = !values.leadCount ? 'Required' : null;
                            errors.assignToProfile = !values.assignToProfile ? 'Required' : null;
                            errors.expectedCloseDate = !values.expectedCloseDate ? 'Required' : null;
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            alert('1')
                            setTimeout(() => {
                                alert(JSON.stringify(values, null, 2));
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                            /* and other goodies */
                        }) => (
                            <>
                                <Grid container spacing={2}>

                                    <Grid item xs={6} md={4}>

                                        <Div>{title}</Div>

                                    </Grid>
                                    <Grid item xs={6} md={8}>

                                        <div style={{ float: 'right' }}>
                                            <Button variant='contained' onClick={() => { assignLead(values) }}>Save</Button>
                                        </div>
                                        <div style={{ float: 'right', marginRight: 10 }}>
                                            <Button variant='outlined' onClick={() => { setFormState(false) }}>Cancel</Button>
                                        </div>

                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} style={{ marginTop: 10 }}>

                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            id="leadCount"
                                            style={textFieldStyle}
                                            label="Lead Count"
                                            defaultValue={values.leadCount}
                                            name="leadCount"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.leadCount}
                                            type="number"
                                        />
                                        <span style={errorMessageStyle}> {errors.leadCount && touched.leadCount}</span>
                                    </Grid>

                                    {
                                        agentList?.length > 0 &&
                                        <Grid item xs={12} md={12}>
                                            <Autocomplete
                                                disablePortal
                                                id="assignToProfile"
                                                options={agentList}
                                                // renderOption={(option, { selected }) => option['name']}
                                                renderInput={(params) => <TextField  {...params} label="Select profile*" />}
                                                name="assignToProfile"
                                                onChange={(event, value) => { setFieldValue('assignToProfile', value) }}
                                                onBlur={handleBlur}
                                                value={values.assignToProfile}
                                            />
                                            <span style={errorMessageStyle}> {errors.assignToProfile && touched.assignToProfile}</span>
                                        </Grid>
                                    }


                                    <Grid item xs={12} md={12}>

                                        <label htmlFor="datepicker">Expected to close on:</label>
                                        <DatePicker placeholderText="Select Expected Close Date" id="datepicker" className="datePickerInput" selected={values.expectedCloseDate} onChange={(date) => setFieldValue('expectedCloseDate', date)} />
                                        <span style={errorMessageStyle}> {errors.expectedCloseDate && touched.expectedCloseDate && errors.expectedCloseDate}</span>

                                    </Grid>

                                </Grid>
                            </>
                        )}
                    </Formik>

                </Box>

            </SwipeableDrawer>
        </div>
    );
}

