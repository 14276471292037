import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import { Input } from 'antd';
const { Search } = Input;

export default function SearchBar({ placeholder = 'Search', onSearch, onClear, onCreate, from = "lead" }) {

    const [searchHint, setSearchHint] = React.useState('')

    return (
        // <>
        // <Search placeholder="input search text" enterButton="Search" size="large" loading />
        // </>
        <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%', height: '45px' }}
        >
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={placeholder}
                inputProps={{ 'aria-label': 'search google maps' }}
                onChange={(event) => {
                    setSearchHint(event?.target?.value || '')
                }}
                value={searchHint}
            />
            <IconButton onClick={() => { onSearch(searchHint) }} type="button" sx={{ p: '10px' }} aria-label="search">
                <SearchIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton sx={{ p: '10px' }} aria-label="directions" onClick={() => { setSearchHint(''); onClear() }}>
                <HighlightOffIcon />
            </IconButton>
            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
            <IconButton sx={{ p: '10px' }} aria-label="directions" onClick={() => { onCreate() }}>
                {
                    from == 'Report' ? <SupportAgentIcon /> : <AddCircleOutlineIcon />
                }

            </IconButton>
        </Paper>
    );
}